import apiEndpoints from '@/models/common/api-endpoints';
import clinicSelector from '@/components/ClinicSelector';
import { mapGetters } from 'vuex';

export default {
  name: 'admin-connectors',
  components: {
    clinicSelector
  },
  data () {
    return {
      magicGUID: '00000000-0000-0000-0000-000000000000',
      card_title: 'Connectors',
      editTitle: 'Edit Connector',
      newTitle: 'Install Connector',
      infoTitle: 'Connector Info',
      createNewBtnTitle: 'Install New Connector',
      keyCodeSuccessMessage: '',
      keyCodeErrorMessage: '',
      info_dialog: false,
      edit_dialog: false,
      new_object_valid: false,
      edit_object_valid: false,
      new_dialog: false,
      newObject: {
        clinicConnectorId: '8d95cddf-408e-404b-b7d8-817214eee3ac',
        code: 'TESTXXL',
        status: 'Pending'
      },
      timer: '',
      mainTimer: '',
      editedItem: {},
      originalItem: {},
      infoItem: {},
      editedIndex: undefined,
      infoIndex: undefined,
      search: undefined,
      loadingTable: true,
      headers: [
        {
          text: 'Connector Name/ID',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Status',
          align: 'start',
          sortable: false,
          value: 'status'
        },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      items: undefined,
      availablePermissions: [
        'GlobalAdmin'
      ],
      rules: {
        required: value => !!value || 'A value is required',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid email address entered';
        },
        minLength: value => {
          return (value || '').length > 2 || 'Clinic name must contain at least 3 characters';
        }
      }

    };
  },
  methods: {
    defaultNewObject () {
      const obj = {
        name: ''
      };

      return obj;
    },
    copyKey () {
      const codeToCopy = document.querySelector('#keyCode');
      codeToCopy.select();
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          this.keyCodeSuccessMessage = 'Copied to clipboard';
          setTimeout(() => {
            this.keyCodeSuccessMessage = '';
          }, 2000);
        } else {
          this.keyCodeErrorMessage = 'Browser prevented copying!';
          setTimeout(() => {
            this.keyCodeErrorMessage = '';
          }, 2000);
        }
      } catch (err) {
        alert('Oops, unable to copy');
      }
    },
    inviteNewObject: async function () {
      // Request to create new connector
      const response = await apiEndpoints.getNewConnectorCodeForCurrentClinic();

      if (response.result) {
        this.newObject = response.data;
        
        // Update the table in the background
        this.retrieveData();

        // Pass through to the UI Dialog
        this.newClinicInstallProgress(this.newObject);

        // Show the dialog
        this.new_dialog = true;
      }
    },
    newClinicInstallProgress (item) {
      // Isolate the viewed item
      this.newObject = Object.assign({}, item);

      // Initialise the timer
      this.timer = setInterval(this.fetchClinicUpdate, 5000);

      this.new_dialog = true;
    },
    fetchClinicUpdate: async function () {
      const response = await apiEndpoints.getConnectorsForCurrentClinic();

      if (response.result) {
        let connector;
        for (connector of response.data) {
          if (connector.code === this.newObject.code) {
            this.newObject = connector;
          }
        }
      }
    },
    editItem (item) {
      // Save an in-progress version of the item to be edited
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      // persist the original version of the item
      this.originalItem = Object.assign({}, item);

      // Show the dialog
      this.edit_dialog = true;
    },
    showInfo (item) {
      // early out if status is pending
      if (item.status && item.status === 'Pending') {
        // use the new device dialog instead
        this.newClinicInstallProgress(item);
        return;
      }
      // Isolate the viewed item
      this.infoIndex = this.items.indexOf(item);
      this.infoItem = Object.assign({}, item);

      // set up the timer
      this.timer = setInterval(this.updateInfo, 5000);

      // Show the dialog
      this.info_dialog = true;
    },
    updateInfo: async function () {
      const response = await apiEndpoints.getConnectorsForCurrentClinic();

      if (response.result) {
        let connector;
        for (connector of response.data) {
          if (connector.clinicConnectorId === this.infoItem.clinicConnectorId) {
            this.infoItem = connector;
          }
        }
      }
    },
    retrieveData: async function () {
      // set the loading state and clear the current items data
      this.loadingTable = true;

      // retrieve the data
      const response = await apiEndpoints.getConnectorsForCurrentClinic();

      if (response.result) {
        // filter and remove
        const tempItems = response.data;
        this.items = tempItems.filter((item) => {
          if (item.clinicConnectorId === null) { 
            return false;
          }
          return true;
        });
      }

      // Set the user table loading state to false
      this.loadingTable = false;
    },
    save: async function () {
      // refresh the user list
      this.retrieveData();

      // close the dialog
      this.close();
    },
    close () {
      this.edit_dialog = false;
    },
    closeNew () {
      // unset the timer
      clearInterval(this.timer);

      // Close the dialog
      this.new_dialog = false;
    },
    closeNewUpdate () {
      // unset the timer
      clearInterval(this.timer);

      // Close the dialog
      this.new_dialog = false;

      // refresh the data
      this.retrieveData();
    },
    closeInfo () {
      // unset the timer
      clearInterval(this.timer);

      // close the dialog
      this.info_dialog = false;
    }
  },
  mounted () {
    this.retrieveData();
  },
  created () {
    this.mainTimer = setInterval(this.retrieveData, 20000); // update the table every 20 seconds
  },
  beforeDestroy () {
    // make sure we have no timers still running
    clearInterval(this.timer);
    clearInterval(this.mainTimer);
  },
  computed: {
    ...mapGetters({
      currentClinic: 'currentClinic',
      allClinics: 'allClinics'
    }),
    connectorHREF: function () {
      return window.location.origin + apiEndpoints.apiDetails.connector;
    }
  },
  watch: {
    currentClinic: function (newVal, oldVal) {
      this.retrieveData();
    }
    // allClinics: function (newVal, oldVal) {
    //   this.retrieveData();
    // }
  }
};
